import React from "react";
import styled, {css} from "styled-components";
import polygonImg from "../../assets/imgs/polygon@2x.png"
import ETHImg from "../../assets/imgs/ETH@2x.png"

import {useGetCurrency} from "../../state/CurrentCoin/hooks";


export function goToHref(){
    window.open("https://polygon.nsure.network/","_black")
}
export const NetSelectWidget: React.FC = () => {
    const {currencyList} = useGetCurrency()
    return (
        <NewSelectStyle>
            <EthWidget>
                <div className="inner ac">
                    <img src={ETHImg} alt=""/>
                    <div>
                        <h2>Ethereum</h2>
                        <h3>Market</h3>
                    </div>
                </div>

            </EthWidget>

            {
                currencyList.map((ev) => {
                    return (
                        <PolygonWapStyle key={ev.name} onClick={goToHref}>
                            <div className={"polygon_style"}>
                                <LeftStyle>

                                    <img src={ev.img} alt=""/>
                                    <div>
                                        <h2>{ev.name}</h2>
                                        <h3>Market</h3>
                                    </div>
                                </LeftStyle>
                                <RightStyle>
                                    <img src={polygonImg} alt=""/>
                                    <p>Polygon</p>
                                </RightStyle>
                            </div>


                        </PolygonWapStyle>
                    );
                })
            }

        </NewSelectStyle>
    );
}


const WapStyles = css`
  display: flex;
  background: #D7F7FF;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  
  &:hover{
    box-shadow: 0px 4px 7px 0px rgba(99, 34, 238, 0.31);
  }

`

const LeftStyle = styled.div`
  display: flex;
  padding: 0 5px;
  align-items: center;

  img {
    display: block;
    margin-right: 10px;
    width: 28px;
  }

  div {
    padding-right: 20px;

    h2 {
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #270D4F;
      line-height: 25px;
      text-transform: Uppercase;

      font-family: PingFangSC-Medium, PingFang SC;
    }

    h3 {
      height: 17px;
      font-size: 12px;
      font-weight: 500;
      color: #9C97AC;

      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 17px;
    }
  }
`


const EthWidget = styled(LeftStyle)`
  height: 64px;
  padding: 6px 12px;

  box-sizing: border-box;
  background: #AC8EF3;
  border-radius: 5px;
  .inner{
    ${WapStyles};
    background: #C7CFFD;
    align-items: center;
    >img{
      width: 29px;
      height: 29px;
    }
  }

`


const RightStyle = styled.div`
  text-align: center;
  background-color: #79DFE9;
  padding: 3px 11px;


  img {
    display: block;
    width: 28px;
    height: 28px;
    margin: 0 auto;
  }

  p {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 1;
  }
`

const PolygonWapStyle = styled.div`
  height: 64px;
  padding: 6px 12px;
  box-sizing: border-box;
  border-radius: 5px;
  .polygon_style {
    ${WapStyles};


   

    .ac {
      background: linear-gradient(90deg, #DF52E5 0%, #6FA0FF 100%);

      h2, h3 {
        color: #FFFFFF;
      }
    }
  }

`

const NewSelectStyle = styled.div`
  padding: 0 30px;
  height: 88px;
  margin-bottom: 30px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: flex-end;
`
